$gutterSizeSmall: 2em;

$gutterSize: 5em;

.storiesOverview {
  width: 100%;
  padding: 0;
  margin: 1em 0;
  display: flex;
  flex-wrap: wrap;

  &.withIntro {
    > div {
      &:first-of-type {
        margin-bottom: calc(#{$gutterSizeSmall});
      }
      @include media(">small") {
        &:first-of-type {
          width: calc((100% - #{$gutterSize}) / 3 * 2 + (#{$gutterSize} / 2));
          margin-right: calc(#{$gutterSize} / 2);
        }
        &:nth-of-type(3n + 2) {
          margin-right: 0;
        }
      }
    }
  }
  &:not(.withIntro) {
    > div {
      &:nth-of-type(3n) {
        margin-right: 0;
      }
    }
  }
  &.loading {
    div {
      background: rgba(#ccc, 0.1);
      padding-top: 100%;
    }
  }
  h4 {
    width: 100%;
    text-align: center;
    display: block;
    position: relative;
    font-size: 1.1em;
    margin: -1em 0 1em 0;
    text-transform: lowercase;
    span {
      display: inline-block;
      background: #fff;
      position: relative;
      padding: 0 1em;
      font-size: 0.8em;
      > span {
        display: inline;
        padding: 0;
        font-size: 1em;
      }
    }
    &:before {
      content: "";
      position: absolute;
      top: 55%;
      left: 0;
      height: 1px;
      width: 100%;
      background: rgba($articleContentGrey, 0.5);
    }
  }
}

.home {
  // background: url("/jaarverslag-2023/img/header-network.svg");
  // background-size: 80vw auto;
  // background-position-x: calc(100vw - 75vw);
  // background-position-y: -35vh;
  // background-attachment: fixed;
  // background-repeat: no-repeat;
  padding-top: 20vh * 0.75;

  // @media (min-height: 700px) {
  //   background-position-x: calc(100vw - 75vw);
  //   background-position-y: -15vh;
  // }

  // @include media(">xl-large") {
  //   background-size: 60vw auto;
  //   background-position-x: calc(100vw - 60vw);
  // }
  // @include media(">xl-large") {
  //   background-size: 40vw auto;
  //   background-position-x: calc(100vw - 40vw);
  // }
}

@keyframes pulse {
  0% {
    opacity: 1;
    transform: none;
  }
  50% {
    opacity: 0.8;
    transform: scale(0.8);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

section {
  background: $backgroundGrey;
  background: #fff;
  padding: 2em;
  z-index: 15;
  position: relative;

  @include media(">small") {
    padding: 4em;
    width: 80vw;
    margin: 8vw auto;
    border-radius: 0.5em;
    max-width: calc(#{map-get($breakpoints, "xl-large")} - 4em);
  }
  @include media(">medium") {
  }
  @include media(">large") {
  }
  @include media(">xl-large") {
    // max-width: calc(#{map-get($breakpoints, "xl-large")} - 4em);
  }

  &:first-of-type() {
    margin-top: 0;
  }

  &.intro2023 {
    display: flex;

    padding: 0;
    position: relative;
    overflow: hidden;
    min-height: 20vw;

    @include media(">small") {
      max-height: 40vh;
      // background: $backgroundGrey;
    }
    .introwrapper {
      padding: 0;
      justify-content: space-between;
      display: flex;
      align-items: center;
      width: 100%;
      flex-direction: column;
      @include media(">small") {
        flex-direction: row;
      }
    }
    .logo {
      padding: 3em;
    }
    .backgroundImage {
      // background: url("../img/intro2022image.jpg");
      height: 100%;
      // width: 100%;
      // background-size: cover;
      // back

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .loadingIcon,
    .scrollArrow {
      position: absolute;
      bottom: -7em;
      left: 50%;
      transform: translateX(-50%);
    }

    .loadingIcon {
      width: 3.5em;
      height: 3.5em;
      margin-top: 0.8em;
      img {
        margin: 0;
        width: 100%;
        height: 100%;
      }
    }
    .scrollArrow {
      img {
        @include media(">small") {
          height: 3.5rem;
          animation: pulse 2s ease-out infinite;
        }
      }
    }

    h1 {
      color: $ui-orange;
      line-height: 0.9;
      font-size: 2.8rem;
      // @include media(">xs-small") {
      //   font-size: 3.8rem;
      // }
      // @include media(">small") {
      //   font-size: 4.4rem;
      // }
      span {
        color: $dgc-purple;
      }
    }
    img {
      &.logo {
        height: 2.2rem;
        margin: 0em;
        width: auto;
        @include media(">small") {
          margin: 1em 0.5em;
          // height: 3.5rem;
        }
      }
    }

    .introduction {
      margin-top: -25vh;
      h1 {
        color: $ui-orange;
      }
    }
  }
  &.prerequisite {
    background: #fff;
    padding: 0;

    .backgroundImage {
      opacity: 0.6;
      z-index: 1;
      overflow: hidden;

      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 200%;
        // background: url("../img/OgenAANP.png");
        background-size: 100% auto;
        background-repeat: no-repeat;

        @include media(">xl-large") {
          transform: translateY(-5%);
        }
      }
    }
    .columns {
      position: relative;
      z-index: 2;
    }
    h1 {
      color: $ui-orange;
    }
    .figure {
      pointer-events: none;
      order: 5;
      //   margin-top: -20em;
      background: $dgc-orange;
      background-image: url("../img/prerequisitebackground2023.png");
      background-size: 100% auto;
      background-repeat: no-repeat;
      position: relative;
      z-index: 10;
      svg {
        max-width: 100%;
        width: 40%;
        left: 5%;
        transform-origin: bottom left;
        position: relative;

        // left: -10vw;
        @include media(">small") {
          left: 0;
          // transform: scale(1.2);
          top: -5vw;
          width: 100%;
        }
        line,
        circle {
          stroke-width: 2;
          stroke: lighten($ui-orange, 20%);
          stroke: #847e79;
        }
        text {
          font-size: 18rem;
          @extend .MarselisBold;
          stroke: #fff;
          stroke-width: 2.5;
          fill: rgba(#fff, 0.26);
          @include media(">medium") {
            // stroke-width: 0;
          }
        }
      }
      > div {
        @include media("<medium") {
          transform: translate(0, 0) !important;
          &.textCircle {
            bottom: 2em !important;
            left: auto !important;
            right: 2em !important;
          }
        }
      }
    }
    .content {
      order: 10;
      padding: 2em;
      @include media(">small") {
        padding: 4em 2em;
      }
    }
  }
  &.prerequisites {
    padding-top: 0;
    padding-bottom: 3em;
    // margin-top: -7vh;
    background: #ede3d9;
    @include media(">small") {
      margin-top: 15vw;
    }

    svg.faces {
      transform: translateY(-70%);
      display: none;
      @include media(">medium") {
        display: block;
      }

      line,
      .backgroundCircle {
        stroke-width: 2;
        stroke: lighten($ui-orange, 20%);
      }
      .faceCircle {
        cursor: pointer;
      }
      .playButton {
        circle {
          stroke-width: 2;
          fill: lighten($ui-orange, 10%);
          stroke: #fff;
        }
        path {
          pointer-events: none;
          cursor: pointer;
          fill: #fff;
          stroke: none;
        }
      }
    }
    .columns {
      @include media(">medium") {
        margin-top: -16vw;
      }
      @include media(">large") {
        margin-top: -34%;
      }
      h2 {
        font-size: 1.5em;
        color: $grey;
        min-height: 2em;
        span {
          display: block;
        }
      }

      .teaser {
        margin-top: 3em;
        @include media(">small") {
          display: flex;
        }

        // border: 2px solid red;

        .image {
          width: 50vw;
          height: 50vw;
          border-radius: 50%;
          background-size: cover;
          background-position: center;
          margin: 0 1em 1em 0;
          @include media(">medium") {
            display: none;
          }
        }
        .intro {
          width: 75vw;
          @include media(">small") {
            padding-left: 1.5em;
          }
          @include media(">medium") {
            width: auto;
            padding-left: 0;
            padding-right: 1.5em;
          }

          // width: 75%;
          // @extend .TitilliumSemiBold;
          // font-size: 0.8em;
        }

        &:hover {
          .imageContainer {
            &:after {
              background: rgba($ui-orange, 0);
            }
          }
        }

        @include media(">medium") {
          margin: 2em;
          &:first-of-type {
            margin-left: -0;
          }
          &:last-of-type {
            margin-right: 0;
          }
        }
      }
      &.prerequisiteOpen {
        padding-top: 5em;
        position: relative;
        flex-direction: column;
        .intro {
          @extend .TitilliumSemiBold;
        }
        .close {
          position: absolute;

          top: 5em;
          right: 0em;

          width: 10em;
          height: 2em;
          text-align: right;
          color: $ui-orange;
          vertical-align: middle;
          @extend .TitilliumSemiBold;
          cursor: pointer;
          @include media(">small") {
            top: 1em;
            right: 2em;
          }
          &:after {
            content: url("/jaarverslag-2022/img/icons/arrow-only.svg");
            width: 0.6em;
            display: inline-block;
            transform: rotate(-90deg);
            margin-left: 0.7em;
            vertical-align: middle;
            position: relative;
            top: -0.1em;
          }
        }
        .teaser {
          @include media(">small") {
            margin: 0 auto;
            width: 80%;
          }
        }
        > div {
          width: 100%;
          @include media(">small") {
            margin: 0 auto;
            width: 80%;
          }

          p {
            margin-top: 0;
          }
          h3 {
            font-size: 1.5em;
            color: $ui-orange;
          }
        }
      }
    }
  }
  &.brandExplainer {
    // background: #ffe9e2;
    position: relative;
    // min-height: 55vh;
    color: #fff;

    .backgroundImage {
      position: absolute;
      top: 0%;
      left: 0;
      width: 100%;
      height: 100%;

      display: flex;
      overflow-x: hidden;
      overflow-y: visible;
      pointer-events: none;

      background-repeat: no-repeat;
      background-position: top left;
      background-size: cover;
      border-radius: 0.5em;

      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(214, 201, 188, 0.7);
        background: linear-gradient(
          90deg,
          rgba(214, 201, 188, 0.7) 0%,
          rgba(166, 158, 145, 0.7) 100%
        );
      }
    }
    .columns {
      justify-content: flex-end;
    }
    .content {
      position: relative;
      @include media(">medium") {
        text-align: right;
        width: 35%;
      }
    }
    .download {
      position: relative;
      z-index: 10;
      margin: 2em 0 6em 0;
      img {
        max-width: 100%;
        width: 300px;
      }
      .buttonContainer {
        margin-top: 1em;
        a {
          display: inline-block;
          border: 0.2em solid $ui-orange;
          background: #fff;
          padding: 0.5em 1em;
          border-radius: 1em;
          text-decoration: none;
          font-weight: 800;
          transition: 0.2s;
          mix-blend-mode: multiply;
          &:after {
            content: url("/jaarverslag-2022/img/icons/arrow.svg");
            color: $ui-orange;
            font-size: 0.7em;
            display: inline-block;
            margin-left: 1em;
            position: relative;
            top: 0.25em;
            transition: 0.2s;
            display: inline-block;
          }
          &:hover {
            // background: rgba(#f5c4b3, 0.5);

            &:after {
              margin-left: 1.8em;
            }
            &:before {
              content: "";
              display: block;
              width: 100%;
            }
          }
        }
      }
    }

    h1 {
      color: #fff;
    }
  }

  $circleSize: 3em;

  &.jaarverslagen {
    background: #fff;
    h2 {
      color: $ui-orange;
      font-size: 2.5em;
    }
    p {
      margin-top: 0;
    }
    .columns {
      display: block;
      > div {
        width: 100%;
        margin-bottom: 1em;
      }
      @include media(">l-large") {
        display: flex;
        > div {
          width: 48%;
        }
      }
    }
    .introduction {
      display: flex;
      justify-content: space-between;
      margin-bottom: 2rem;

      .text {
        width: 65%;
      }
      .image {
        width: 30%;

        img {
          max-width: 100%;
        }
      }
    }
    .jaarverslag-cat {
      border-radius: 0.2em;
      // padding: 2.2em;
      padding: 1.2em 0;
      position: relative;
      text-align: center;
      h3 {
        font-size: 2em;
        color: #fff;
        @include media(">small") {
          max-width: 80%;
        }
        position: relative;
        padding: 0 1.2em;
        text-align: left;
      }
      .infographic {
        height: auto;
        margin: 3em 0 5em 0;
        position: relative;
        width: 100%;
        @include media(">medium") {
          width: 80%;
        }
        @include media(">large") {
          width: 70%;
        }
        @include media(">l-large") {
          width: 100%;
        }
      }
      &.geschillen-commissies {
        background-color: $ui-orange;
        &:before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: url("../img/NetwerkHomeSWAK2.png");
          background-size: 500% auto;
          background-position: center;

          opacity: 0.55;
        }
      }
      &.klachtenloketten {
        background: #7b7773;
        height: 100%;
        &:before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: url("../img/NetwerkHomeSWAK.png");
          background-size: 450% auto;
          background-position: top left;
          mix-blend-mode: multiply;
          opacity: 0.55;
        }
      }
      .links {
        position: relative;
        padding: 0 10%;
        @include media(">small") {
          padding: 0 1.2em;
        }
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .link {
          min-width: 290px;

          @include media(">small") {
            min-width: calc(50% - 1em);
          }
          @include media(">xl-large") {
            width: calc(50% - 1em);
          }
          background: #fff;
          border-top-right-radius: 2.5em;
          border-bottom-right-radius: 2.5em;
          height: 5em;
          padding: 0.5em 4em 0.5em 1em;
          @include media(">l-large") {
            height: 3.5em;
          }
          @include media(">xl-large") {
            height: 5em;
          }

          margin: 0 1em 1em 0;
          position: relative;
          color: $ui-orange;
          a {
            display: flex;
            align-items: center;

            text-decoration: none;
            height: 100%;
          }

          &:hover {
            .button-link-arrow {
              transform: translateY(-1.7em) translateX(0.2em);

              @include media(">l-large") {
                transform: translateY(-1em) translateX(0.4em);
              }
              @include media(">xl-large") {
                transform: translateY(-1.7em) translateX(0.2em);
              }
            }
          }
          img {
            height: 100%;
            width: auto;
          }
          label {
            @extend .MarselisRegular;
            text-align: left;
            font-size: 1.2em;
          }
          .button-link-arrow {
            position: absolute;
            top: 50%;
            right: 1em;
            height: calc(100% - 1.5em);
            width: auto;
            transform: translateY(-1.7em);
            transition: 0.2s;
            @include media(">l-large") {
              transform: translateY(-1em);
            }
            @include media(">xl-large") {
              transform: translateY(-1.7em);
            }
          }
          // &:after {
          //   content: url("data:image/svg+xml,%3Csvg width='16' height='29' viewBox='0 0 16 29' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_339_10)'%3E%3Cpath d='M0.350098 28.48L14.4101 14.41L0.350098 0.350006' stroke='currentColor'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_339_10'%3E%3Crect width='15.12' height='28.83' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");

          //   // color: $ui-orange;
          // }
        }
      }
    }
  }
  &.earlierYears {
    background: $ui-orange;
    color: #fff;
    padding-bottom: 2em;
    .columns {
      flex-direction: column;
      @include media(">medium") {
        flex-direction: row;
      }
      div {
        width: 100%;
        position: relative;
        &.text {
          margin-right: 2em;
        }
      }
    }

    h2 {
      color: #fff;
      font-size: 2.5em;
    }
    .text {
      @include media(">medium") {
        max-width: 25vw;
      }
    }
    .disclaimer {
      font-size: 0.8em;
      font-style: italic;
    }
    svg {
      // transform: translateX(4em);
      margin-top: 4em;
      line,
      circle {
        stroke-width: 2;
        stroke: lighten($ui-orange, 20%);
      }
      .years {
        circle {
          fill: $ui-orange;
          cursor: pointer;
          transition: fill 0.2s;
          &:hover {
            fill: lighten($ui-orange, 4);
          }
        }
      }
      .yearText {
        pointer-events: none;
      }
    }
    .links {
      padding-top: 50%;
      background-image: url("../img/clock.jpg");
      background-position: left 20%;
      background-size: cover;
      &__container {
        position: absolute !important;
        top: 0;
        right: 0;
        height: 100%;

        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        position: relative;
        z-index: 2;
        @include media(">small") {
          width: 75% !important;
        }
      }
      &__link {
        width: calc(100% / 3) !important;
        user-select: none;
        background: rgba($ui-orange, 0.8);
        position: relative;
        transition: 0.2s;
        &:hover {
          background: $ui-orange;
        }
        a {
          color: #fff;
          text-decoration: none;
          font-size: 2.2rem;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: "FFMarselisSlabWebProRegular", serif;
        }
        &:nth-of-type(2n) {
          background: rgba(#958c7d, 0.53);
          &:hover {
            background: #958c7d;
          }
        }
      }
    }
    // .links {
    //   background-image: url("../img/clock.jpg");
    //   background-position: left 20%;
    //   margin-left: 4rem;
    //   position: relative;
    //   &:after {
    //     content: "";
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     width: 100%;
    //     height: 100%;
    //     pointer-events: none;
    //     background: rgba(#958c7d, 0.5);
    //     z-index: 1;
    //   }
    //   &__container {
    //     display: flex;
    //     flex-wrap: wrap;
    //     justify-content: flex-end;
    //     height: 100%;
    //     position: relative;
    //     z-index: 2;
    //   }
    //   &__link {
    //     // height: 50%;
    //     width: 30% !important;
    //     padding-top: 30%;
    //     background: $dgc-orange;

    //     user-select: none;
    //     background: rgba($ui-orange, 0.8);
    //     position: relative;
    //     a {
    //       color: #fff;
    //       text-decoration: none;
    //       font-size: 5em;
    //       position: absolute;
    //       top: 0;
    //       left: 0;
    //       width: 100%;
    //       height: 100%;
    //       display: flex;
    //       justify-content: center;
    //       align-items: center;
    //     }
    //     &:nth-of-type(2n) {
    //       background: rgba($grey, 0.8);
    //     }
    //   }
    // }
  }
  &.storyOverview {
    overflow: hidden;
  }
  &.storyOverview,
  &.QnA {
    h2 {
      color: $ui-orange;
      font-size: 2.5em;
    }
  }
}

$categories: (
  jaarverslag-consumenten: (
    kleur: $dgc-orange,
    char: "C",
  ),
  jaarverslag-klachtenloket-kinderopvang: (
    kleur: $dgc-purple,
    char: "KK",
  ),
  jaarverslag-beroep-en-bedrijf: (
    kleur: $dgc-red,
    char: "B",
  ),
  jaarverslag-klachtenloket-zorg: (
    kleur: $dgc-green,
    char: "KZ",
  ),
  vastlegging-geschillencommissies-zorg: (
    kleur: $dgc-green,
    char: "Z",
  ),
);
@each $categorie, $params in $categories {
  div.shortcuts a {
    &.#{$categorie} {
      &:before {
        border-color: map-deep-get($params, "kleur");
      }

      &:after {
        // content: map-deep-get($params, "char");
        color: map-deep-get($params, "kleur");
      }
    }
  }
}

.button {
  text-transform: uppercase;
  font-size: 0.8rem;
  border-radius: 2em;
  background: none;
  display: inline-block;
  width: auto;
  text-decoration: none;
  cursor: pointer;
  @extend .TitilliumSemiBold;
  line-height: 1.5;
  &.simple {
    font-size: 0.9rem;
  }
  &.solid {
    padding: 0.05em 1em 0.2em 1em;
  }

  &.outline {
    border: 0.2em solid #fff;
    padding: 0.5em 1em;
    color: #fff;
  }
  &:focus {
    outline: 0;
  }

  span {
    vertical-align: middle;
    line-height: 1.5;
    strong {
      @extend .TitilliumBold;
    }
  }
  svg {
    position: relative;
    top: 0.1em;
    display: inline-block;
    vertical-align: middle;
    width: 1em;
    margin-left: 0.6em;
    &.download {
      top: 0em;
      margin-left: 0.8em;

      width: 0.7em;
    }
  }
}

.cijferIndex {
  width: 100%;
  min-height: 100vw;
  // background: #f1f3f5;
  display: flex;
  margin-bottom: 10em;

  .childrenContainer {
    margin: 0 auto 5em auto;

    .noFilterResults {
      padding: 10em;
      text-align: center;
      font-size: 1em;
    }
    .children {
      display: flex;
      flex-wrap: wrap;
      // padding: 0 6em 6em 0em;

      > div {
        margin: 2em 2em 0 0;
        width: calc(50% - 1em);

        &:nth-child(2n) {
          margin-right: 0;
        }
        @include media(">small") {
          width: calc((100% / 3) - (4em / 3));
          &:nth-child(2n) {
            margin-right: 2em;
          }
          &:nth-of-type(3n) {
            margin-right: 0;
          }
        }
      }
    }
  }
  > div {
    width: 100%;
  }
  h2.faq {
    color: $ui-orange;
    margin: 2em 0 2em 0;
  }
}

/* KLEUREN */
@each $categorie, $params in $categories {
  &.in-#{$categorie} {
    .tegel {
      border-color: map-deep-get($params, "kleur");
      .image {
        .imageWrapper {
          border-color: map-deep-get($params, "kleur");
        }
      }
      span {
        color: map-deep-get($params, "kleur");
      }
    }
  }
}

.homeSearch {
  input {
    width: 100%;
    border: 0;
    padding: 0.5em;
    font-size: 1.5rem;
    background: #fff;
    border-radius: 0.2em;
    color: $grey;
    margin-top: 1em;
    outline: none;
    background: $backgroundGrey;
    @extend .MarselisRegular;
  }
}

.filterContainer {
  display: none;
  @include media(">small") {
    display: flex;
  }
  position: fixed;
  top: 1em;
  z-index: 10;
  left: 0;
  width: 100%;
  .fakeSidebar {
    width: 20vw;
    flex-shrink: 1;
    flex-grow: 0;
  }
  .withSidebar {
    margin: 0;
    // max-width: calc(#{map-get($breakpoints, "medium")} + 2rem);

    @include media(">medium") {
      max-width: calc(
        #{map-get($breakpoints, "medium")} - #{$sidebarWidthLarge} - 2rem
      );
      margin: 0 auto;
      padding: 0;
    }
    @include media(">large") {
      max-width: calc(
        #{map-get($breakpoints, "large")} - (#{$sidebarWidthLarge} / 2) - 2rem
      );
    }
  }

  .inputContainer {
    position: relative;
    input {
      @extend .MarselisRegular;
      font-size: 0.8em;
      display: inline-block;
      width: 100%;
      background: $backgroundGrey;
      // border-bottom-right-radius: 1em;
      // border-bottom-left-radius: 1em;
      border-radius: 1em;
      padding: 0.8em 1em 0.8em 3.5em;
      border: 0;
      &:focus {
        outline: none;
      }
    }
    span.emptyFilter {
      &::selection {
        background: none;
      }
      img {
        cursor: pointer;
        position: absolute;
        top: 50%;
        right: 0em;
        display: block;
        width: 2.5em;
        height: 2.5em;
        font-size: 1em;
        font-weight: bold;
        text-decoration: none;
        text-align: center;
        line-height: 1.2;
        transform: translateY(-50%);
        &::selection {
          background: none;
        }
      }
    }
    &:after {
      content: url("/img/icons/icon-filter.svg");
      position: absolute;
      top: 0.5em;
      left: 0.8em;
      width: 1em;
      transform: scale(1.2);
      transform-origin: left center;
    }
  }
}

$breadcrumbCircleSize: 2.2em;
$breadcrumbCircleSizeAchtergronden: 1.6em;
$lineWidth: 2px;
$lineHeight: 0.6em;
// $lineColor: #fff;
// $spaceBetweenSections: 1.5em;
// $scaleFactorSmallBreadcrumb: 0.8;

div.breadcrumbs {
  ul.breadcrumbs {
    padding: 0;
    margin: 0;
    list-style: none;
    font-size: 1em;
    li {
      position: relative;
      white-space: nowrap;
      // margin-bottom: $lineHeight * 1.1;
      line-height: 1;
      @extend .TitilliumSemiBold;
      text-transform: uppercase;

      a {
        text-decoration: none;
        color: inherit;
      }
      &.home {
        padding-top: 0;
        user-select: none;
      }
      .circle {
        width: $breadcrumbCircleSize;
        height: $breadcrumbCircleSize;
        border: 0.105em solid $ui-orange;
        border: 2px solid $ui-orange;
        display: inline-block;
        border-radius: 50%;
        vertical-align: middle;
        position: relative;
        font-size: 1em;
        background: #fff;
        &:after {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          @extend .MarselisBold;
          font-size: 1.1em;
        }
      }
      span {
        margin-left: 0.5em;
        vertical-align: middle;
        display: inline-block;
        white-space: break-spaces;
        max-width: calc(100% - #{$breadcrumbCircleSize});
        font-size: 0.8em;

        span {
          display: block;
          margin-left: 0;
        }
      }
      &:before {
        // line
        content: "";
        display: block;
        width: $lineWidth;
        height: $lineHeight;
        background: $ui-orange;
        margin: 0.4em 0;
        position: relative;
        left: calc(#{$breadcrumbCircleSize} / 2 - #{$lineWidth}/ 2);
      }

      &.home {
        a {
          .circle {
            background: url("/img/icons/icon-home.svg") $ui-orange;
            background-position: center;
            background-size: 60%;
            background-repeat: no-repeat;
            border-width: 0.1em;
            border-color: $ui-orange;
            &:after {
              // char
              display: none;
            }
          }
          img {
            width: 14em;
          }
          span {
            font-size: 1em;
            text-transform: none;
            color: $articleContentGrey;
            @extend .TitilliumRegular;
            white-space: nowrap;
            span {
              color: $ui-orange;
              @extend .TitilliumSemiBold;
            }
          }
          // &:after {
          //   @include media(">small") {
          //     content: url("https://www.samenwerkenaankwaliteit.nl/jaarverslag-2018/img/home-icon.svg");
          //     width: 1em;
          //   }
          // }
        }
        &:before {
          display: none;
        }
      }
      &.intro {
        // padding: 2em 0;
        text-transform: none;
        span {
          @extend .MarselisRegular;
          color: #e87647;
          font-size: 0.9em;
          line-height: 1.2;
          padding-left: 1em;
          display: block;
        }
      }
    }
  }
  &.viewMode-achtergrond {
    ul.breadcrumbs li {
      &:before {
        // line
        left: calc(
          #{$breadcrumbCircleSizeAchtergronden} / 2 - #{$lineWidth}/ 2 + (
              #{$breadcrumbCircleSize} - #{$breadcrumbCircleSizeAchtergronden}
            ) / 2
        );
      }
      &:not(.home) {
        a {
          .circle {
            width: $breadcrumbCircleSizeAchtergronden;
            height: $breadcrumbCircleSizeAchtergronden;
            left: calc(
              (
                (
                    #{$breadcrumbCircleSize} - #{$breadcrumbCircleSizeAchtergronden}
                  ) / 2
              )
            );
          }
          span {
            margin-left: 1em;
            color: $blackish;
          }
        }
      }
      &.active {
        .circle {
          background: $ui-orange;
        }
      }
    }
  }
}

@each $categorie, $params in $categories {
  div.breadcrumbs {
    &.in-#{$categorie} {
      ul.breadcrumbs li {
        color: map-deep-get($params, "kleur");
        a {
        }
        .circle {
          border-color: map-deep-get($params, "kleur");
          @include media(">small") {
            &:after {
              // content: map-deep-get($params, "char");
              color: map-deep-get($params, "kleur");
            }
          }
        }

        &:before {
          background: map-deep-get($params, "kleur");
        }
      }
    }
  }
}

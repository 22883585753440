// IE
@media all and (-ms-high-contrast: none) {
  aside {
    a.homelogo {
      svg {
        height: 100%;
      }
    }
  }
  article .articleContent .infographic {
    height: 1359.5px;
  }

  article .header .articleHeader svg {
    width: 700px;
    height: calc(700px / 150 * 115);
  }
  .prerequisites svg.faces {
    width: 100%;
    height: 33vw;
    @include media(">large") {
      height: 25vw;
    }
    @include media(">xl-large") {
      height: 20vw;
    }
  }
}

// EDGE <=18
@supports (-ms-ime-align: auto) {
  aside {
    a.homelogo {
      svg {
        height: 100%;
      }
    }
  }
}

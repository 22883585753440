/* PDF OVERRULES */
.pdf {
  .appHeader {
    display: none !important;
  }
  .pageWrapper {
    min-height: 0;
  }
  aside {
    width: 0;
    .logo {
      position: fixed;
      top: 1em;
      left: 1em;
    }
    > *:not(.header) {
      display: none;
    }
  }

  // cijferindex
  .cijferIndex {
    min-height: 0;
    margin-bottom: 0;
  }
  .filterContainer,
  .childrenContainer,
  .faq,
  .accordion,
  .shareAndDownload {
    display: none !important;
  }

  article {
    .logo {
      position: absolute;
      top: 4em;
      left: -0.2em;
      width: 40%;
    }
    &.closed {
      .introduction {
        &:after {
          display: none;
        }
      }
    }
    .relatedArticles,
    .see-also {
      display: none !important;
    }
    .articleContent {
      max-width: 92%;
    }
  }
  .exampleJudgments {
    h3,
    .accordion .item .accordionHeader,
    .accordion .item .content {
      padding-left: 0 !important;
    }
    .accordion {
      display: block !important;
      .arrow {
        display: none;
      }
    }
  }
  .story {
    padding: 0 !important;
    .heading {
      h1 {
        margin-top: 1em !important;
      }
    }
  }
  .heading {
    h1 {
      width: 60%;
    }
    h2 {
      width: 85%;
    }
  }
  .articleHeader {
    margin-top: -6em;
    margin-right: -10em;
  }

  .storiesOverview {
    display: none !important;
  }

  footer {
    padding: 2em 2em 1em 2em;
    > div {
      > div {
        flex-direction: row !important;
        flex-wrap: wrap;
        > div {
          padding-right: 3em;
          &:first-of-type {
            margin-bottom: 2em;
          }
        }
      }
    }
    .links {
      display: none;
    }
  }
  .cookieconsentOverlay {
    display: none !important;
  }
}

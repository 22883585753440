@mixin overlay() {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 200vh;
  background: rgba($dgc-purple, 0.15);
  z-index: 1000;
  opacity: 0;
  transition: 0.6s;
  pointer-events: none;
  z-index: 1;
  &.active {
    opacity: 1;
  }
}

/// Map deep get
/// @author Hugo Giraudel
/// @access public
/// @param {Map} $map - Map
/// @param {Arglist} $keys - Key chain
/// @return {*} - Desired value
@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }
  @return $map;
}

.articleHeaderContainer {
  margin-top: 10em;
  margin-bottom: -15em;
  width: 80%;
  margin-left: 10%;

  @include media(">small") {
    // display: block;
    position: absolute;
    top: 2rem;
    right: 2rem;
    width: 35vw;
    margin: 0;
    height: calc(35vw / 150 * 115); // SVG ratio
  }

  @include media(">xl-large") {
    max-width: calc(530px);
    max-height: calc(530px / 150 * 115); // SVG ratio
    right: calc((100vw - 700px) / 2 - 530px);
    right: calc((100vw - 700px - 45vw) / 2);
    // border: 2px solid red;
  }

  .articleHeader {
    height: auto;
    position: relative;
    height: 100%;
    width: 100%;
    z-index: 15;
    svg {
      width: 100%;
      top: 0;
      left: 0;
      width: 100%;
      height: auto;

      transform-origin: right center;
    }
  }

  @include media("print") {
    position: absolute;
    top: -3em;
    right: 3em;
    width: 45%;
    height: auto;
  }
}

header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;

  padding: 2rem;
  z-index: 1050;
  // background: #fff;
  display: flex;
  justify-content: space-between;
  .logo {
    position: relative;
    h1 {
      margin: 0;
    }
    > img {
      // width: $wrapperPaddingSmall;
      // @include media(">small") {
      //   width: calc(#{$wrapperPadding} - 2rem * 2);
      // }
      // display: inline-block;
      width: 8rem;
      // margin-left: 2em;
    }
  }
  .search {
    img {
      width: 3vw;
    }
  }
}

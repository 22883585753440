aside {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  overflow: visible;
  height: 5rem;
  z-index: 15;
  font-size: 0.9rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: hidden;

  transition: height 0.2s;
  transform-origin: center top;
  will-change: height;
  overflow: hidden;

  @include media(">medium") {
    position: sticky;
    top: 0;
    left: 0;
    height: 100vh;
    width: $sidebarWidthLarge;
    flex-shrink: 1;
    flex-grow: 0;
    padding: 0.5em;
    justify-content: space-between;
  }
  @include media(">large") {
    padding: 1em;
  }

  &.mobileMenuOpen {
    // padding: 1em;
    height: 100%;
    div.footer,
    .navigation {
      display: block;
    }
  }

  // display: none;
  > * {
    z-index: 2;
  }
  .sticky {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .background {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    background: $backgroundGrey;
    // transition: 0.2s;
    transform-origin: left center;
    z-index: 1;
    @include media(">small") {
      opacity: 0.75;
    }
  }

  a.homelogo {
    z-index: 10;
    position: absolute;
    top: 2em;
    left: 0;
    display: none;
    @include media(">medium") {
      display: block;
    }
    @include media(">medium") {
      background: #fff;
      border-top-right-radius: 2.15em;
      border-bottom-right-radius: 2.15em;
      box-shadow: 1em 1em 2em -0.2em rgba(0, 0, 0, 0.31);
      width: 90%;
      max-width: 250px;
      height: 4.3em;

      text-align: right;
    }
    svg {
      height: calc(100% - 0.1em);
      margin-right: 0em;
      display: inline-block;
      max-width: 100%;

      .background {
        box-shadow: 10px 10px 68px 0px rgba(0, 0, 0, 0.75);
      }
    }
  }
  .mobileMenuHeader {
    padding: 1em;
    display: flex;
    border-top: 0.2em solid $dgc-orange;
    justify-content: space-between;
    flex-shrink: 1;
    img {
      cursor: pointer;
      width: 2rem;
      // margin-left: 1em;
      &.logo {
        width: 12rem;
        // border: 2px solid red;
      }
    }
    @include media(">medium") {
      display: none;
    }
  }
  .header {
    position: relative;
    opacity: 0;
    display: none;
    @include media(">large") {
      display: block;
    }
    a {
      text-decoration: none;

      img {
        max-width: 100%;
      }
      .logo {
        font-size: 1.8em;
        @include media(">medium") {
          font-size: 2.1em;
        }
      }
    }
  }

  .navigation {
    display: none;

    padding: 1em;

    @include media(">medium") {
      display: block;
      flex-grow: 1;
      margin-top: 6.5rem;
      height: 50%;
      display: block;
      overflow: auto;
      margin-bottom: 1em;
    }
  }
  .search {
    text-decoration: none;
    font-size: 1em;
    img {
      width: 2em;
      margin-right: 0.5em;
    }
    .searchField {
      margin-bottom: 1.33rem;
    }
  }
  div.footer {
    position: relative;
    display: none;
    padding: 4em 1em;
    @include media(">medium") {
      padding: 0;
      display: block;
    }
    button {
      display: block;
      width: 100%;
      @extend .TitilliumSemiBold;
      padding: 0.5em;
      font-size: 1.2em;
      text-transform: none;
      text-align: left;
      &:before {
        content: url("/img/icons/file-download-solid.svg");
        display: inline-block;
        // height: 1em;
        width: 0.9em;
        position: relative;
        top: 0.2em;
        margin: 0 0.75em 0 0.5em;
      }
    }
    a {
      color: $articleContentGrey;
      background: rgba(#fff, 0.8);
      border-radius: 0.2em;
    }
    .share {
      padding: 2em 0 3em 0;

      strong {
        text-align: center;
        display: block;
        position: relative;
        font-size: 1.1em;
        span {
          display: inline-block;
          background: $backgroundGrey;
          position: relative;
          padding: 0 1em;
        }
        &:before {
          content: "";
          position: absolute;
          top: 50%;
          left: 0;
          height: 1px;
          width: 100%;
          background: $articleContentGrey;
        }
      }
      .shareLinks {
        text-align: center;
        margin-top: 0.7em;
        a {
          display: inline-block;
          width: 1.7em;
          margin: 0 0.4em;
          img {
            max-width: 100%;
          }
        }
      }
    }
  }
}

footer {
  background: #404247;
  color: #fff;
  position: relative;
  z-index: 16;
  @include media(">small") {
    font-size: 0.8rem;
  }

  > div {
    > div {
      padding: 3em 0;
      display: flex;
      flex-direction: column;
      @include media(">small") {
        flex-direction: row;
      }

      > div {
        @include media(">small") {
          width: 33%;
          padding-right: 1em;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          &:first-of-type() {
            width: 40%;
            margin-right: 2em;
          }
        }
        h1.logo {
          color: #fff;
          font-size: 2rem;
        }

        .border {
          border-top: 1px solid #fff;
          margin-top: 1em;
          @include media(">small") {
            border-top: none;
            border-left: 1px solid #fff;
            padding-left: 1em;
            margin-top: 0em;
          }
        }

        h4 {
          @extend .MarselisBold;
        }
        p,
        .p {
          margin: 1em 0 0 0;
        }
        a {
          color: #fff;
          text-decoration: none;
        }
        ul {
          list-style: none;
          margin: 1em 0 0 0;
          padding: 0;
        }
      }
    }
  }
  .links {
    display: flex;
    justify-content: center;
    width: 100%;

    a {
      display: inline-block;

      font-size: 1.2em;
      @extend .TitilliumSemiBold;
      text-decoration: none;
      position: relative;
      @include media(">small") {
        margin: 0 1em;
        &:not(:first-of-type):before {
          content: "-";
          color: #fff;
          margin-right: 1em;
        }
      }

      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 1.4em;
        width: calc(100% - 1.4em);
        height: 1px;
        background: #fff;
        opacity: 0;
        transition: 0.2s;
      }
      &:first-of-type {
        &:after {
          left: 0;
          width: 100%;
        }
      }
      &:hover {
        &:after {
          opacity: 1;
        }
      }
      // &:hover{
      //   text-decoration: underline;
      //   &:before{
      //     text-decoration: none !important;

      //   }
      // }
    }
  }
}

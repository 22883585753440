#QnA {
  background: $ui-orange;
  h2 {
    color: #fff;
  }
  .header {
    margin: 0;
    background: #fff;
    list-style: none;
    border-bottom: 1px solid #ccc;
  }
  section {
    margin: 0;
    padding: 1em;
    border-bottom: 1px solid #ccc;
    background: #fff;

    &:last-of-type {
      border-bottom: 0px;
    }
  }
  padding-bottom: 5em;
}

.page {
  padding-top: 3em;

  .logo {
    font-size: 3.4rem;
  }
  .pageHeader {
    margin-top: 5rem;
    a {
      text-decoration: none;
    }
  }
  .pageTitle {
    text-transform: capitalize;
  }
  .loadingIcon {
    position: absolute;
    top: 50vh;
    left: 50%;
    width: 2.5em;
    transform: translateX(-50%);
  }
  .layoutBlock {
    margin: 1em 0;
    &.colofon {
      margin-bottom: 2.5em;
      .row {
        margin: 1em 0;
        padding: 0.2em 0;
        @include media(">small") {
          display: flex;
        }

        .name {
          padding-right: 1em;
          @extend .TitilliumBold;
        }
      }
    }
  }
  &.voorwoord {
    .videoContainer {
      // background: red;
      text-align: center;
      video {
        width: 100%;
        // max-width: 500px;

        margin: 0 auto;
      }
    }

    .columns {
      margin: 3em 0 5em 0;
      img {
        max-width: 400px;
      }
      .buttonContainer {
        text-align: right;
        margin: 1em 0;
        @include media(">small") {
          margin: 0;
        }
        a {
          display: inline-block;
          border: 0.2em solid $ui-orange;
          background: #fff;
          padding: 0.5em 1em;
          border-radius: 1em;
          text-decoration: none;
          font-weight: 800;
          transition: 0.2s;
          mix-blend-mode: multiply;
          &:after {
            content: url("/jaarverslag-2023/img/icons/arrow.svg");
            color: $ui-orange;
            font-size: 0.7em;
            display: inline-block;
            margin-left: 1em;
            position: relative;
            top: 0.25em;
            transition: 0.2s;
            display: inline-block;
          }
        }
      }
    }
  }
}

* {
  box-sizing: border-box;
}
// print PDF
@page {
  margin: 1cm;
  size: A4;
}
@page:left {
  @bottom-left {
    content: "Page " counter(page) " of " counter(pages);
  }
}

/* breakpoints */
$breakpoints: (
  xs-small: 380px,
  small: 768px,
  medium: 810px,
  //ipad portrait
  large: 1000px,
  l-large: 1200px,
  xl-large: 1500px,
  fucking-large: 2000px,
);

/* Colors */
$blackish: #565d62;
$offwhite: #f7f7f9;
$grey: #7c7672;
$lightgrey: #e3e0de;
$dgc-orange: #f7935c;
$dgc-yellow: #f39456;
$dgc-purple: #665e84;
$dgc-red: #f26854;
$dgc-green: #2c8980;

$ui-orange: #e87647;
$ui-blue: #565d62;

$consumenten: $dgc-orange;
$beroep-bedrijf: $dgc-red;
$klachtenloket-kinderopvang: $dgc-purple;
$klachtenloket-zorg: $dgc-green;

/* layout colors */
$backgroundGrey: #f2f3f5;

/* KLEUREN */
$categories: (
  jaarverslag-consumenten: (
    kleur: $dgc-orange,
    char: "C",
  ),
  jaarverslag-klachtenloket-kinderopvang: (
    kleur: $dgc-purple,
    char: "K",
  ),
  jaarverslag-beroep-en-bedrijf: (
    kleur: $dgc-red,
    char: "B",
  ),
  jaarverslag-klachtenloket-zorg: (
    kleur: $dgc-green,
    char: "Z",
  ),
);

/* article */
$articleContentGrey: $grey;

/* Sizes */
$sidebarWidthSmall: 20vw;
$sidebarWidthLarge: 20vw;

/* Fonts */
@import url("//hello.myfonts.net/count/36665f");
@font-face {
  font-family: "FFMarselisSlabWebProRegular";
  src: url("/jaarverslag-2023/fonts/36665F_0_0.eot");
  src: url("/jaarverslag-2023/fonts/36665F_0_0.eot?#iefix")
      format("embedded-opentype"),
    url("/jaarverslag-2023/fonts/36665F_0_0.woff2") format("woff2"),
    url("/jaarverslag-2023/fonts/36665F_0_0.woff") format("woff"),
    url("/jaarverslag-2023/fonts/36665F_0_0.ttf") format("truetype");
}
@font-face {
  font-family: "FFMarselisSlabWebProBold";
  src: url("/jaarverslag-2023/fonts/36665F_1_0.eot");
  src: url("/jaarverslag-2023/fonts/36665F_1_0.eot?#iefix")
      format("embedded-opentype"),
    url("/jaarverslag-2023/fonts/36665F_1_0.woff2") format("woff2"),
    url("/jaarverslag-2023/fonts/36665F_1_0.woff") format("woff"),
    url("/jaarverslag-2023/fonts/36665F_1_0.ttf") format("truetype");
}

@import url("https://fonts.googleapis.com/css?family=Titillium+Web:400,600,700");

.MarselisRegular {
  font-family: "FFMarselisSlabWebProRegular", serif;
  font-weight: normal;
}

.MarselisBold {
  font-family: "FFMarselisSlabWebProBold", serif;
  font-weight: normal;
}

.TitilliumRegular {
  font-family: "Titillium Web", sans-serif;
  font-weight: 400;
}
.TitilliumSemiBold {
  font-family: "Titillium Web", sans-serif;
  font-weight: 600;
}
.TitilliumBold {
  font-family: "Titillium Web", sans-serif;
  font-weight: 700;
}

/* general */
html,
body {
  -webkit-print-color-adjust: exact;

  color: $articleContentGrey;
  font-size: 14px;
  @extend .TitilliumRegular;
  // background: $offwhite;
  background: #fff;
  line-height: 1.5;
  @include media(">small") {
    background: #f1f0f0;
    font-size: calc(11px + 0.25vw);
  }

  @include media(">xl-large") {
    font-size: 14.75px;
  }

  @media print {
    width: 210mm;
    height: 297mm;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "FFMarselisSlabWebProRegular", serif;
  font-weight: normal;
  font-style: normal;
  margin: 0;
  line-height: 1.2;
  margin-bottom: 0.5em;
  strong {
    font-family: "FFMarselisSlabWebProBold", serif;
    font-weight: normal;
    color: #000;
  }
}
h1 {
  font-size: 2.5em;
}

*::selection {
  background: rgba($ui-orange, 0.5); /* WebKit/Blink Browsers */
  // color: rgba($ui-orange, 0.2);
}
*::-moz-selection {
  // background: rgba(255,255,255,0.5); /* Gecko Browsers */
  color: inherit;
}

p {
  display: block;
  margin: 1em 0;
  padding: 0;
}

a {
  color: $ui-orange;
  @extend .TitilliumSemiBold;
}

/* LAYOUT */
.pageWrapper {
  min-height: 100vh;
  display: flex;
  > div {
    flex-grow: 1;
    max-width: 100vw;
    @include media(">large") {
      width: 10%;
    }
  }
  @include media("print") {
    padding: 0 5em;
  }
}

$wrapperPaddingSmall: 2rem;
$wrapperPaddingMedium: 5rem;
$wrapperPadding: 12rem;
.wrapper {
  width: 100%;
  padding: 0 $wrapperPaddingSmall;

  @include media(">small") {
    padding: 0 $wrapperPaddingMedium 0 $wrapperPaddingMedium;
  }
  @include media(">medium") {
    padding: 0 $wrapperPadding 0 $wrapperPadding;
  }
  @include media(">large") {
    padding: 0 $wrapperPadding 0 $wrapperPadding;
  }
  @include media(">xl-large") {
    width: calc(#{map-get($breakpoints, "xl-large")} - 12em);

    margin: 0 auto;
    padding: 0;
  }
  // @include media(">large") {
  //   padding: 0 calc((100vw - #{map-get($breakpoints, "large")}) / 2) 0
  //   calc((100vw - #{map-get($breakpoints, "large")}) / 2);
  // }
  &.withSidebar {
    padding: $wrapperPaddingSmall;
    margin: 0 auto;
    @include media(">medium") {
      max-width: 635px;
      margin: 0 auto;
      padding: 0;
    }
    @include media(">large") {
      max-width: 700px;
    }
  }
}

.columns {
  display: flex;
  flex-direction: column;

  @include media(">medium") {
    justify-content: space-between;
    width: 100%;
    flex-direction: row;
  }
  > div {
    @include media(">medium") {
      width: 48%;
    }
  }
}

.logo {
  color: $ui-orange;
  line-height: 0.9;
  font-size: 1.4rem;
  text-decoration: none;
}

/* LOGOS */

.icon-down-arrow {
  &:after {
    content: url("/img/icons/arrow-down-solid.svg");
    display: inline-block;
    // height: 1em;
    width: 0.9em;
    position: relative;
    top: 0.2em;
    margin-left: 1em;
  }
}
.loadingIcon {
  width: 1em;
}

.underline {
  text-align: center;
  display: block;
  position: relative;
  font-size: 1.1rem;
  margin: -1em 0 1em 0;
  text-transform: lowercase;

  span {
    display: inline-block;
    background: #fff;
    position: relative;
    padding: 0 1em;
    font-size: 0.8em;

    > span {
      display: inline;
      padding: 0;
      font-size: 1em;
    }
  }
  &:before {
    content: "";
    position: absolute;
    top: 60%;
    left: 0;
    height: 1px;
    width: 100%;
    background: rgba($articleContentGrey, 0.5);
  }
}

$appheaderHeight: 2em + 3.6em; // set in layout.jsx
.appHeader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  // height: $appheaderHeight;// set in layout.jsx
  z-index: 10;
  @include media(">small") {
    background-color: $ui-orange;
  }
  &:after {
    background: url("../img/NetwerkHomeSWAK2.png");
    background-size: 150% auto;
    background-position: top center;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.4;
    @include media(">small") {
      content: "";
    }
  }

  &:before {
    content: "";
    opacity: 0;
    transition: 0.2s;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url("../img/ogen.jpg");

    background-position: top center;
    background-repeat: no-repeat;
    background-size: 150% auto;
    @include media(">small") {
      background-size: auto 100%;
    }
  }
  &.withImage {
    &:before {
      opacity: 1;
    }
  }
  // &::after {
  //   content: "";
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 100%;
  //   background: rgba($ui-orange, 0.75);
  //   // mix-blend-mode: multiply;
  // }
}
.networkBackground {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 110vh;
  z-index: 5;
  background: url("../img/NetwerkHomeSWAK.png");
  background-size: 150% auto;
  background-position: top center;
  background-repeat: no-repeat;
  opacity: 0.8;
  display: none;
  @include media(">small") {
    display: block;
  }

  svg {
    width: 100%;
    height: auto;
  }
}

article {
  // position: static !important;

  // max-width: 700px;
  // margin: 0 auto;
  width: 100%;
  padding: 5em 0 5em 0;
  .header {
    position: relative;

    .articleHeader {
      display: block;
      width: 100%;
      margin: 0;
      position: absolute;
      top: 0;
      left: 0;
      height: auto;

      @include media(">small") {
        left: auto;
        right: -10%;
        width: 90%;
        z-index: 15;
      }
      svg {
        position: static;
      }
    }
  }

  .heading {
    // width: 70%;
    margin: 1em 0 0 0;
    // padding-top: 10em;
    will-change: padding-top;
    transition: 0.2s;
    @include media(">small") {
      margin: 3em 0 1em 0;
      width: 72%;
    }
    @include media(">medium") {
      margin: 5em 0 1em 0;
      width: 63%;
    }
    @include media(">large") {
      margin: 7em 0 2em 0;
      width: 65%;
    }
    @include media(">xl-large") {
    }
    a.back {
      @extend .TitilliumRegular;
      color: $articleContentGrey !important;
      display: block;
      margin-bottom: 0.2em;
      text-decoration: none;
    }
    h1 {
      color: $ui-orange;
      font-size: 2em;
      span {
        &.organisation {
          font-size: 0.7em;
          display: block;
        }
        &.type {
          text-transform: capitalize;
        }
      }
    }
    h2 {
      @extend .TitilliumSemiBold;
      font-size: 1.3rem;

      // text-transform: uppercase;
      strong {
        @extend .TitilliumSemiBold;
      }
    }
  }
  &.closed {
    .introduction {
      // width: 55%;
      overflow: hidden;
      max-height: 15vh;
      position: relative;
      &:after {
        content: "";
        background: #f1f0f0;
        // background: linear-gradient(
        //   180deg,
        //   rgba(255, 255, 255, 0) 0%,
        //   rgba(255, 255, 255, 1) 100%
        // );
        background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(#fff, 1) 100%
        );
        @include media(">small") {
          background: linear-gradient(
            180deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(#f1f0f0, 1) 100%
          );
        }
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
      .loadingIcon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  &.open {
    @include media("<=small") {
      padding-bottom: 10em;
      padding-top: 10em;
    }
  }
  &.loading {
    .introduction {
      height: 12vh;
    }
  }
  h3 {
    @extend .TitilliumSemiBold;
    color: $ui-orange;
    margin: 1em 0;
  }
  .introduction {
    @extend .TitilliumSemiBold;
  }
  .readMore {
    @extend .button;
  }
  .articleContentPlaceholder {
    min-height: 100vh;
  }

  > .introduction {
    @include media(">small") {
      max-width: 85%;
    }
  }
  > .header {
    .heading {
      @include media(">small") {
        margin-top: 10em;
        max-width: 85%;
      }
    }
  }
  .articleContent {
    position: relative;
    overflow: hidden;
    @include media(">small") {
      max-width: 85%;
    }
    h3 {
      margin-bottom: 0.2em;
    }
    p {
      &:first-of-type {
        // margin-top: 0;
      }
    }
    div.infographic,
    div.exampleJudgments {
      margin-top: 5em;
      h3 {
        padding-left: 1em;
        margin-bottom: 1em;
      }

      .arrow {
        background: none !important;
        position: absolute;
        bottom: 0em;
        left: 0em;
        padding: 0.5em !important;
        margin: 0 0 0 0 !important;
        text-align: right;
        height: auto !important;
        width: 100% !important;
        transform: none !important;
        color: $ui-orange;
        @extend .TitilliumSemiBold;
        &:before {
          content: "lees meer";
        }
        &:after {
          content: url("/img/icons/arrow-only.svg");
          display: inline-block;
          width: 0.5em;
          vertical-align: middle;
          margin: 0 1em 0 0.5em;
          transform-origin: center;
          transform: rotate(90deg);
        }
      }
      .item {
        .accordionHeader {
          h4 {
            margin-bottom: 0.5em;
          }
        }
        &.open {
          .arrow {
            &:before {
              content: "sluiten";
            }
            &:after {
              transform: rotate(270deg);
            }
          }
        }
        &:hover {
          .arrow {
            transform: scale(0.9803921569) !important;
          }
        }
      }
    }
    .infographic {
      page-break-before: always;
      page-break-after: always;
      h2 {
        text-align: center;
        text-transform: uppercase;
        @extend .TitilliumSemiBold;
      }
    }
  }

  &.story {
    @include media(">small") {
      padding-top: 0;
    }
    // .backgroundimage {
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   width: 100%;
    //   height: 70%;
    //   background-color: #cfccfc;
    // }
    .heading {
      padding-top: clamp(10px, 70%, 400px);
    }
    .headerBackgroundImage {
      position: absolute;
      top: -3em;
      left: 0;
      width: 100%;
      height: clamp(10px, 70vh, 400px);

      object-fit: cover;
      // opacity: 0.2;
      pointer-events: none;
      z-index: 1;
      display: none;
      @include media(">small") {
        display: block;
      }
    }
  }
  .breadcrumbs {
    margin-left: $breadcrumbCircleSize * -1;
    ul.breadcrumbs {
      li {
        &:last-of-type {
          display: none;
        }
      }
    }
  }
  .see-also {
    margin-top: 2em;
  }
}

/* LAYOUT BLOKKEN, OOK GEBRUIKT IN FAQ */
.layoutBlock {
  &.streamer {
    @extend .MarselisRegular;

    padding: 0.5em 0;
    color: $dgc-orange;
    font-size: 1.5em;
    margin: 1em 3em;
    line-height: 1.2;
    margin: 1em 3em 1em 0;
    border-left: 0.1em solid $dgc-orange;
    padding-left: 1.5em;
  }
  &.titel {
    border-bottom: 2px solid #ccc;
    padding: 1em 0 0.2em 0;
    color: $dgc-orange;
    font-size: 2em;
  }
  // a.articleLink {
  //   display: block;
  //   text-decoration: none;
  //   padding-left: 1em;
  //   position: relative;
  //   font-weight: 600;
  //   font-size: 0.85em;

  //   &:before {
  //     content: ">";
  //     left: 0;
  //     top: 0;
  //     position: absolute;
  //     margin-right: 0.75em;
  //   }
  // }

  &.logos {
    display: flex;
    align-items: center;
    // justify-content: space-between;
    margin-top: 4em;
    div {
      border-right: 1px solid #ccc;
      padding: 0 1em;
      img {
        max-height: 5em;
        max-width: 100%;
        // margin-right: 1em;
      }
      &:last-of-type {
        border: none;
      }
    }
  }
  &.links {
    a {
      display: block;
      text-decoration: none;
      &:before {
        content: ">";
        margin-right: 0.5em;
      }
    }
  }
  &.timeline {
    table {
      margin-top: 1em;
      border-top: 1px solid $grey;
      width: 100%;
      tr.header {
        th {
          background: rgba($dgc-orange, 0.3);
          color: $dgc-orange;
        }
      }
      td,
      th {
        text-align: left;
        padding: 0.5em;
      }
      td {
        &:before {
          //minimale breedte voor 1e kolom
          content: "";
          display: block;
          width: 3em;
        }
      }
      tr {
        &:nth-child(odd) {
          td {
            color: $dgc-orange;
          }
        }
      }
    }
  }
  &.bezetting-commissies {
    h3 {
      color: $dgc-orange;
      pointer: cursor;
    }
    .names {
      display: none;
      flex-direction: column;
      margin: 1em 0;
      border-bottom: 1px solid #ccc;
      padding-bottom: 1em;
      @include media(">small") {
        flex-direction: row;
      }

      > div {
        @include media(">small") {
          width: 30%;
        }
        padding-left: 1em;
        strong {
          color: $dgc-orange;
        }
      }
      &.open {
        display: flex;
        position: relative;
        &:before {
          content: "";
          position: absolute;
          top: -0.5em;
          left: 0.5em;
          height: calc(100% - 1em);
          width: 1px;
          background: $dgc-orange;
        }
      }
    }
  }
  &.explanation {
    .accordion {
      .item {
        padding: 0;
        background: none;
        border-bottom: 0;
        border-top: none !important;

        .accordionHeader {
          text-align: right;
          h4 {
            font-size: 0.8em;
            position: relative;
            display: inline-block;
            padding-left: 2.5em;
            &:before {
              content: "";
              background: $ui-orange;
              border-radius: 50%;
              display: inline-block;
              // height: 1em;
              width: 1.5em;
              height: 1.5em;
              margin-right: 0.5em;
              position: relative;
              top: 0.2em;
              margin-left: 1em;
              padding: 0.2em;
              position: absolute;
              left: -0.5em;
              top: -0.2em;
            }
            &:after {
              content: "?"; //url("/img/icons/arrow-down-solid.svg");
              color: #fff;
              font-size: 1em;
              display: inline-block;
              width: 1em;
              height: 1em;
              margin-right: 0.5em;
              position: absolute;
              left: 0.5em;
              top: 0em;
            }
          }
        }
        &.open {
          .accordionHeader {
            background: $backgroundGrey;
            background: #fff;
          }
        }
        &:hover {
          transform: none;
        }
        .content {
          font-size: 0.8em;
          // border-top: 1px solid $grey;
          background: $backgroundGrey;
          background: #fff;
          padding: 2em 2em 2em 2em;
          h3,
          h4,
          strong {
            color: $ui-orange;
          }
        }
      }
    }
  }
  &.link_to_other_article {
    p {
      display: block;
      text-align: center;
      padding: 1em 0;
      margin-bottom: 0;
      span {
        font-style: italic;
        margin: 0 auto;
        width: auto;
        padding-right: 0.2em;
        display: inline-block;
        position: relative;
        padding: 0 1em;
        font-size: 0.8em;
        span {
          font-size: 1em;
          background: #fff;
          display: inline-block;
          position: relative;
          padding: 0 0.5em;
          &:before {
            display: none;
          }
        }
        &:before {
          content: "";
          position: absolute;
          top: 55%;
          left: 0;
          height: 1px;
          width: 100%;
          background: rgba($articleContentGrey, 0.5);
        }
      }
    }
    .links {
      display: flex;
      justify-content: center;
    }
  }
  &.images {
    display: flex;

    justify-content: space-between;
    > div {
      width: 100%;
      margin: 1em;
      &:first-of-type {
        margin-left: 0;
      }
      &:last-of-type {
        margin-right: 0;
      }
      img {
        width: 100%;
        max-width: 100%;
      }
    }
  }
  &.verdieping {
    @extend .TitilliumSemiBold;
    &.withImage {
      > div {
        margin-top: 0em;
      }
      > img {
        margin-top: 5em;
        margin-left: 1em;
      }
    }

    > div {
      // background: $backgroundGrey;
      background: #fff;
      position: relative;
      padding: 2em;
      margin-top: 5em;
    }
    h3 {
      text-transform: uppercase;
    }

    span {
      position: absolute;
      top: 0;
      left: 0;
      display: inline-block;
      width: auto;
      height: auto;
      background: #f1f0f0;
      font-size: 1em;
      padding: 0.2em 0.5em;
    }
  }
  &.video_embed {
    position: relative;
    padding-bottom: 56.25%;
    overflow: hidden;
    max-width: 100%;
    height: auto;
  }

  .embed-container iframe,
  .embed-container object,
  .embed-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  &.paragraph {
    video {
      width: 100%;
      height: auto;
    }
  }
}

@each $categorie, $params in $categories {
  .cijferIndex {
    &.in-#{$categorie} {
      article {
        .heading {
          h1 {
            color: map-deep-get($params, "kleur");
          }
          a {
            color: map-deep-get($params, "kleur");
          }
        }
        strong {
          color: map-deep-get($params, "kleur");
        }
        .streamer {
          color: map-deep-get($params, "kleur");
          border-color: map-deep-get($params, "kleur");
        }
        .titel {
          color: map-deep-get($params, "kleur");
        }
        h3 {
          color: map-deep-get($params, "kleur");
        }
        a {
          color: map-deep-get($params, "kleur");
        }
      }
    }
  }
}

.storyImageBackground {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: #e1edec;
  height: clamp(10px, 70vh, 400px);
  z-index: 1;
  display: none;

  @include media(">small") {
    display: block;
  }
}

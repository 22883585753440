.audioplayer{
    position: relative;
    margin-top: 2em;
    &__background{
      position: absolute;
      top: 0;
      left: 0;
      height: auto;
      width: 400px;
      pointer-events: none;
      // @include media(">small") {
      //   width: 60%;
      // }
    }
    .interface{
      width: 400px;
      
      display: flex;
      align-items: center;
      padding: 22px 6px 0;
      
  
    }
    .button{
        width: 4em;
        .audiobutton{
            color: #E77648;
            position: relative;
            top: -1px;
            path{
                fill: currentColor;
                stroke: none;
            }
        }
    }
    input[type=range] {
        -webkit-appearance: none;
        margin: 18px 0;
        // width: 65%;
        width: 80%;
        // @include media(">small") {
        //   width: 50%;

        // }
      }
      input[type=range]:focus {
        outline: none;
      }
      input[type=range]::-webkit-slider-runnable-track {
        width: 100%;
        height: 0.2em;
        cursor: pointer;
        box-shadow: none;
        background: $dgc-orange;
        border-radius: 0;
        border: none;
      }
     
     
      input[type=range]::-moz-range-track {
        width: 100%;
        height: 0.2em;
        cursor: pointer;
        box-shadow: none;
        background:$dgc-orange;
        border-radius: 0;
        border: 0.2px solid #010101;
      }

      input[type=range]:focus::-webkit-slider-runnable-track {
        background: darken($dgc-orange,10%);
      }
      input[type=range]::-ms-thumb {
        box-shadow: none;
        border: 2px solid $grey;
        height:1.2em;
        width: 1.2em;
        border-radius: 50%;
        background: #fff;
        cursor: pointer;
        margin-top: -0.5em;
      }
      input[type=range]::-moz-range-thumb {
        box-shadow: none;
        border: 2px solid $grey;
        height:1.2em;
        width: 1.2em;
        border-radius: 50%;
        background: #fff;
        cursor: pointer;
        margin-top: -0.5em;
      }
      input[type=range]::-webkit-slider-thumb {
        box-shadow: none;
        border: 2px solid $grey;
        height:1.2em;
        width: 1.2em;
        border-radius: 50%;
        background: #fff;
        cursor: pointer;
        -webkit-appearance: none;
        margin-top: -0.5em;
      } 
      input[type=range]::-ms-track {
        width: 100%;
        height: 8.4px;
        cursor: pointer;
        background: transparent;
        border-color: transparent;
        border-width: 16px 0;
        color: transparent;
      }
      input[type=range]::-ms-fill-lower {
        background: #2a6495;
        border: 0.2px solid #010101;
        border-radius: 2.6px;
        box-shadow: none;

      }
      input[type=range]::-ms-fill-upper {
        background:$dgc-orange;
        border: 0.2px solid #010101;
        border-radius: 2.6px;
        box-shadow: none;

      }
     
      input[type=range]:focus::-ms-fill-lower {
        background:$dgc-orange;
      }
      input[type=range]:focus::-ms-fill-upper {
        background: #367ebd;
      }
      
}
.cookieconsentOverlay {
  position: fixed !important;
  z-index: 1000 !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $ui-orange !important;
  background: rgba(#000, 0.5) !important;
  padding: 2em;
  pointer-events: none;
  display: none;

  .CookieConsent {
    background: #fff !important;
    width: 80% !important;
    display: block !important;
    @include media(">small") {
      width: 400px !important;
    }
    top: 50% !important;
    left: 50% !important;
    bottom: auto !important;
    transform: translate(-50%, -50%);
    height: auto !important;
    color: #000 !important;
    padding: 2em;
    font-weight: 800 !important;
    font-size: 1.2rem;
    > div {
      flex: 1 !important;
    }

    button {
      font-size: 0.9em !important;
      padding: 0.7em 1em !important;
      border-radius: 0.2em !important;

      &#rcc-confirm-button {
        background: $ui-orange !important;
        color: #fff !important;
        font-weight: 800;
      }
      &#rcc-decline-button {
        font-size: 0.7em !important;
        background: #fee9e2 !important;
        color: $ui-orange !important;
        margin-top: 0 !important;
        //   color: #000 !important;
      }
    }

    span {
      display: block;
      font-size: 1rem;
      font-weight: 400;
      margin-top: 0.5em;
    }
  }
}

.showCookieOverlay {
  pointer-events: none;
  .cookieconsentOverlay {
    display: block;
    pointer-events: all;
  }
}

.searchField {
  color: #000;
  pointer-events: all;
  text-align: center;
  position: relative;
  font-size: 1em;

  &.searchViewVisible {
    opacity: 1;
    pointer-events: all;
  }
  &:after {
    content: url("/jaarverslag-2023/img/icons/icon-search.svg");
    position: absolute;
    top: 50%;
    left: 0;
    width: 1em;
    transform: scale(2.2) translate(-3%, -6%);
    transform-origin: left center;
  }
  .inputContainer {
    position: relative;

    span.emptySearch {
      cursor: pointer;
      position: absolute;
      top: -0.2em;
      right: 1em;
      display: block;
      width: 1.5em;
      height: 1.5em;
      font-size: 1em;

      font-weight: bold;
      text-decoration: none;

      text-align: center;

      line-height: 1.2;
    }
    svg {
      position: absolute;
      top: 50%;
      right: 0.5em;
      width: 1em;
      transform: translateY(-35%);
    }
    input {
      border-radius: 0.75em;
      border: 0;
      // background: darken($sidebarGrey, 10%);
      padding: 0.4em 1em 0.4em 3.5em;
      font-size: 0.75em;
      width: 100%;

      background: #fff;

      border-radius: 1em;

      @extend .MarselisRegular;

      &:focus {
        outline: none;
      }

      &::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: $grey;
        opacity: 1; /* Firefox */
      }
      :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: $grey;
      }
      ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: $grey;
      }
    }
  }
  .mobileButton {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    @include media(">medium") {
      display: none;
    }
  }
}

.overlay {
  @include overlay;
}

div.navigation {
  // group should be movable to create space for search results
  transition: all 0.4s;
  .helperText {
    display: none;
    @include media(">small") {
      // color: $lineColor;
      margin-top: 1em;
      display: block;
    }
  }
}
div.searchResults {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  color: #000;
  font-size: 1.2rem;
  padding: 1em;
  @include media(">small") {
    padding: 5em;
  }
  > div {
    background: #fff;
    border-radius: 1em;
    padding: 2em;
    overflow: hidden;
    z-index: 2;
    position: relative;
    &.background {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      border-radius: 0;
      background: rgba($offwhite, 0.9);
      &:after {
        content: none;
      }
    }
    &:after {
      content: "";
      background: rgb(255, 255, 255);
      background: -moz-linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 80%
      );
      background: -webkit-linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 80%
      );
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 80%
      );
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 5em;
    }
  }

  h2.searchResultsTitle {
    color: $ui-orange;
  }
  @extend .MarselisRegular;
  .loadingSpinner {
    top: 20%;
  }
  .searchField {
    margin-top: 3rem;
    margin-bottom: 2rem;
    .inputContainer {
      position: relative;
      @include media(">small") {
        width: 30%;
      }
      text-align: left;
      input {
        background: $backgroundGrey;
      }
      &:after {
        // content: "";
        height: 0.1em;
        width: calc(100% - 6em);
        left: 3em;
        bottom: 0;
        position: absolute;
        background: $ui-orange;
      }
    }
  }
  .resultsContainer {
    max-height: calc(100vh - 28rem);

    overflow: auto;
    h4.underline {
      margin: 1em 0;
    }
    &.noResults {
      font-size: 0.8em;
      text-align: center;
      padding-bottom: 5em;
    }
  }
  .button {
    background: $dgc-purple;
    border-radius: 0.2em;
    padding: 0.5em;
    font-size: 0.8rem;
    display: inline-block;
    width: auto;
    color: #fff;
    text-decoration: none;
    vertical-align: middle;
    float: right;
    small {
      font-size: 0.8em;
      text-align: right;
      display: block;
    }
  }

  .loadingIcon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 2em;
  }
}
.searchSuggestions {
  color: #000;
  display: none;
  margin-top: 2em;

  @include media(">small") {
    display: block;
  }

  ul {
    list-style: none;
    margin-top: 0;
    padding: 0;
    // margin-top: -1em;
    // background: #fff;
    // border-bottom-left-radius: 0.75em;
    // border-bottom-right-radius: 0.75em;
    li {
      font-size: 0.8rem;
      padding: 0.2em 0;
      float: left;

      cursor: pointer;
      &:after {
        content: " - ";
        display: inline-block;
        margin: 0 0.6em;
      }
      &:last-of-type {
        border: 0;
        &:after {
          content: "";
        }
      }

      span {
        color: $dgc-orange;
      }
    }
  }
}

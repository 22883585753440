@import "./scss/sanitize.scss";
@import "./scss/iOS.scss";
@import "./scss/_include-media.scss";
@import "./scss/mixins.scss";
@import "./scss/general.scss";
@import "./scss/components/button.scss";
@import "./scss/home2023.scss";
@import "./scss/page.scss";
@import "./scss/components/homeSearch.scss";
@import "./scss/components/accordion.scss";
@import "./scss/components/tegelThumbnail.scss";
@import "./scss/components/breadcrumbs.scss";
@import "./scss/components/cijferIndex.scss";
@import "./scss/components/cijferIndexFilterContent.scss";
@import "./scss/components/header.scss";
@import "./scss/components/sidebar.scss";
@import "./scss/components/storiesOverview.scss";
@import "./scss/components/storyThumbnail.scss";
@import "./scss/components/qna.scss";
@import "./scss/components/articleHeader.scss";
@import "./scss/components/footer.scss";
@import "./scss/components/article.scss";
@import "./scss/components/shareArticle.scss";
@import "./scss/components/searchField.scss";
@import "./scss/components/articleListSmall.scss";
@import "./scss/components/audioPlayer.scss";
@import "./scss/pdf.scss";
@import "./scss/ie.scss";
@import "./scss/cookieConsent.scss";
main,
article {
  position: relative;
}

.tegel {
  background: $offwhite;
  text-decoration: none;
  display: block;
  color: inherit;
  border-bottom: 0.3em solid $grey;
  // padding: 0.5em;
  // height: 10vw;
  span {
    display: block;
    color: $ui-orange;
    font-weight: bold;
  }
  &.compact {
    font-size: 0.8rem;
  }
  &.open {
    font-size: 1rem;
    width: 100%;
  }
  h1 {
    font-size: 1.3em;
    min-height: 2.3em;
    color: #000;
  }
  .image {
    position: relative;
    overflow: hidden;
    .imageWrapper {
      padding-top: 100%;
      background: rgba(#ccc, 0.2);
      position: relative;
      transform: translateY(-20%) scale(1.4);
      border: 0.2em solid $grey;
      // border-radius: 0 0 50% 50%;
      background-position: center;
      background-size: cover;
      position: relative;
      overflow: hidden;
      .imageContainer {
        background: rgba(#ccc, 0.2);
        position: absolute;
        top: 4.5em;
        left: 2em;
        width: calc(100% - 4em);
        height: calc(100% - 4.5em);
        background-position: center;
        background-size: cover;
        transform: scale(1.1);
        @include media(">small") {
          transform: scale(2);
        }
        @include media(">medium") {
          transform: scale(1);
        }

        // img {
        //   display: block;
        //   position: absolute;
        //   top: 50%;
        //   left: 50%;
        //   width: 100%;

        //   border: 0.2em solid $ui-orange;
        //   transform: translate(-50%, -60%) scale(1.1);
        // }
      }
    }
  }
  .content {
    padding: 1em;
    position: relative;
    .arrow {
      width: 2em;
      height: 2em;
      position: absolute;
      bottom: 1em;
      right: 1em;
      line,
      polyline {
        stroke: $ui-orange;
      }
    }
  }
  .contains {
    text-align: right;
    .count {
      color: #fff;
      background: $ui-orange;
      display: inline-block;
      width: auto;
      height: 1.5em;
      line-height: 1.5em;
      text-align: center;
      border-radius: 1em;
      position: relative;
      padding: 0 0.5em;
      span {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    ul {
      list-style: none;
      display: none;
    }
    &:hover {
      ul {
        display: block;
      }
    }
  }
  &.small {
    display: flex;
    font-size: 0.8rem;
    margin-bottom: 1em;
    border-bottom: 0;

    .image {
      width: 7em;
      height: 7em;
      .imageWrapper {
        padding: 0;
        height: 100%;
        transform: none;
        border: none;
        .imageContainer {
          width: 100%;
          top: 0;
          left: 0;
          height: 100%;
        }
      }
    }
    .content {
      width: 100%;
    }
  }
}

.tegelsOverviewSmall {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

@each $categorie, $params in $categories {
  .in-#{$categorie} {
    .tegel {
      .content {
        .arrow {
          line,
          polyline {
            stroke: map-deep-get($params, "kleur");
          }
        }
      }
    }
  }
}

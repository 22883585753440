.articleLinks {
  h4.underline {
    color: $ui-orange;
    // margin: 2em 0 2em 0;
  }
  .links {
    padding-top: 1em;
    display: flex;
    flex-direction: column;
    @include media(">medium") {
      flex-direction: row;
    }
    > div {
      padding: 0;
      width: 100%;
    }
    h4 {
      display: none;
      color: $ui-orange;
      text-transform: capitalize;
      margin-bottom: 1em;
      @extend .TitilliumSemiBold;
      margin-top: 1em;
      @include media(">medium") {
        margin-top: 0;
      }
    }
    .items {
      display: flex;
      flex-wrap: wrap;
      @include media("<=medium") {
        > div,
        > a {
          width: calc(50% - 0.5em);
          margin-right: 1em;
          &:nth-of-type(2n) {
            margin-right: 0;
          }
        }
      }
      .storyThumbnail,
      .tegel {
        width: calc(50% - 0.5em);
        margin-right: 1em;
        &:nth-of-type(2n) {
          margin-right: 0;
        }
      }
      .tegel {
        @include media(">medium") {
          width: calc(100% / 3 - 2em / 3);
          margin-right: calc(2em / 2);
          &:nth-of-type(2n) {
            margin-right: calc(2em / 2);
          }
          &:nth-of-type(3n) {
            margin-right: 0;
          }
        }
      }
    }
  }
  &.multiple-types {
    .links {
      > div {
        @include media(">medium") {
          &:first-of-type {
            padding-right: 2em;
          }
          &.stories {
            width: 50%;
          }
          &:last-of-type {
            padding-left: 2em;
          }
        }
        &:first-of-type {
          @include media(">medium") {
            border-right: 1px solid $ui-orange;
          }
        }
        .tegel {
          width: calc(50% - 1em / 2);
          margin-right: 1em;
          &:nth-of-type(2n) {
            margin-right: 0 !important;
          }
          &:nth-of-type(3n) {
            margin-right: 1em;
          }
        }
        // .storyThumbnail {
        //   width: 100%;
        //   margin-right: 0;
        // }
      }
      h4 {
        display: block;
      }
    }
    &.largeStories {
      .links {
        > div {
          .tegel {
            width: 100%;
            margin-right: 0 !important;
          }
          @include media(">medium") {
            &.stories {
              width: 200%;
            }
          }
        }
      }
    }
    &.smallStories {
      .links {
        .storyThumbnail {
          width: 100% !important;
          margin-right: 0 !important;
        }
      }
    }
  }
}

.shareAndDownload {
  position: sticky;
  bottom: 0;
  right: 1em;
  width: 100%;
  background: #fff;
  font-size: 0.8em;
  z-index: 10;
  padding-top: 2em;
  background: linear-gradient(180deg, hsla(0, 0%, 100%, 0), #fff 30%);
  @include media(">small") {
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(#f1f0f0, 1) 30%
    );
  }

  button {
    @extend .TitilliumSemiBold;
    padding: 0.5em;
    font-size: 1.2em;
    text-transform: none;
    text-align: left;
    &:before {
      content: url("/img/icons/file-download-solid.svg");
      display: inline-block;
      // height: 1em;
      width: 0.9em;
      position: relative;
      top: 0.2em;
      margin: 0 0.75em 0 0.5em;
    }
  }
  .share {
    padding: 2em 0 2rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .line {
      height: 1px;
      background: $ui-orange;
      flex-grow: 1;
      margin: 1em;
    }

    .shareLinks {
      text-align: center;
      margin-top: 0.7em;
      span,
      a {
        cursor: pointer;
        display: inline-block;
        width: 1.7em;
        margin: 0 0.4em;
        border-radius: 50%;
        background: $ui-blue;
        img {
          max-width: 100%;
          width: 100%;
          height: 1.7em;
          transform-origin: center;
          transform: scale(0.6);
        }
      }
    }
  }
}

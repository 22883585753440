.accordion {
  .item {
    border-bottom: 1px solid $lightgrey;
    transition: 0.2s;
    background: lighten($lightgrey, 10%);

    .accordionHeader {
      padding: 1em;
      cursor: pointer;
      position: relative;
      h4 {
        font-size: 1.2em;

        flex-grow: 1;
        margin-bottom: 0;
      }
      .arrow {
        // position: absolute;
        // top: 1em;
        // right: 1em;
        float: right;
        margin: 0em 1em;
        height: 1.3em;
        width: 1.3em;
        background: url("/jaarverslag-2023/img/icons/arrow-only.svg");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        transition: 0.2s;
        transform-origin: center;
        transform: rotate(90deg);
      }

      @extend .TitilliumRegular;
    }
    .content {
      padding: 0 1em 1em 1em;
      @include media(">small") {
        padding: 0 4em 1em 1em;
      }
    }
    a {
      @extend .button;
      font-size: 0.8em;
    }
    &.open {
      background: #fff;
      .arrow {
        transform: rotate(270deg);
      }
    }
    &:not(.open) {
      &.hasContent {
        &:hover {
          transform: scale(1.02);
          background: #fff;
        }
      }
    }
    &:first-of-type {
      &.open {
        border-top: 1px solid $lightgrey;
      }
    }
  }
}

.storyThumbnail {
  display: inline-block;
  width: 100%;
  margin-right: 0;

  vertical-align: top;
  margin-bottom: calc(#{$gutterSizeSmall});

  @include media(">small") {
    width: calc((100% - #{$gutterSize}) / 3);
    margin-right: calc(#{$gutterSize} / 2);
    margin-bottom: calc(#{$gutterSize} / 2);
  }

  &.storyThumbnail {
    background: rgba($lightgrey, 0.9);
    &.red {
      background: rgba($dgc-red, 0.9);
    }
    &.orange {
      background: rgba($dgc-orange, 0.9);
    }
    &.green {
      background: rgba($dgc-green, 0.9);
    }
    &.purple {
      background: rgba($dgc-purple, 0.9);
    }
    &.yellow {
      background: rgba($dgc-yellow, 0.9);
    }
  }
  .image {
    opacity: 0.4;
    transition: 0.2s;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    mix-blend-mode: multiply;
  }
  .imageContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    z-index: 1;
    pointer-events: none;
    mix-blend-mode: multiply;
    transition: 0.5s;
  }
  &:hover {
    .image {
      opacity: 0.6;
      .imageContainer {
        transform: scale(1.1);
      }
    }
  }
  a {
    text-decoration: none;
    position: relative;
    display: block;
    height: 100%;
    padding: 2em;
    h2 {
      color: #fff;
      font-size: 1.6em;
      margin-bottom: 3em;
      position: relative;
      z-index: 1;
    }
    h3 {
      @extend .TitilliumSemiBold;
      text-transform: uppercase;
      position: absolute;
      bottom: 0.5rem;
      left: 0;
      width: 100%;
      padding: 0;
      z-index: 1;
      font-size: 1.1em;
      span {
        background: #fff;
        padding: 0.1em;
        color: $ui-orange;

        margin-top: 0.3em;
        display: inline-block;
        &.name {
          max-width: 80%;
          color: $blackish;
        }
      }
    }
  }
  &.small {
    font-size: 0.8em;
    margin-bottom: 1em;

    // &:nth-of-type(2n) {
    //   margin-right: 0;
    // }

    // @include media("<=medium") {
    //   .imageContainer {
    //     background-size: auto 100%;
    //     background-repeat: no-repeat;
    //     background-position: center right;
    //   }
    // }
    a {
      padding: 1em;
    }
    h2 {
      margin-bottom: 3.5em;
    }
    h3 {
      // position: relative;
      // bottom: 0;
      margin: 1em 0 0 0;
    }
  }
}
